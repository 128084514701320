<template>
    <basic-page class="incantalia" :pagedata="this.pageData" bannerimg="/img/incantalia-banner.jpg">
        <div class="container-beige intro container">
            
            <h3 class="occhiello occhiello-beige">
                {{ lang=='it' ? 'Eccellenze da gustare' : 'Experiencing Excellence in Food'}}
            </h3>

            <h2>
                {{ lang=='it' ? 'Un negozio che non c\'èra' : 'A Brand New Concept Store'}}
            </h2>

        </div>

        <img class="filigrana-incantalia left" src="../assets/filigrana-incantalia-left.png" alt="filigrana-incantalia">

        <div class="container" v-if="this.pageData.field_page_ui_testo && this.pageData.field_page_ui_testo[0]">
             <div class="central row-box">

                <div class="row">
                    <div class="column text-container">
                        <h2 :class="['section-title',  this.lang ]">{{ this.pageData.field_page_ui_testo[0].attributes.title }}</h2>
                        <p v-html="this.pageData.field_page_ui_testo[0].attributes.field_ui_testo[0].processed"></p>
                    </div>
                </div>

                <div class="row text-container">
                    <div class="img img-1" v-parallax="-0.05"><img src="../assets/incantalia-collezione-prodotti.jpg" width="375" height="auto" alt="">
                    </div>
                    <div class="column-2">
                        <p v-html="this.pageData.field_page_ui_testo[0].attributes.field_ui_testo[1].processed"></p>
                        <div class="img img-1" v-parallax="-0.1"><img src="../assets/foto-illy-negozio-incantalia.jpg" width="375" height="auto" alt=""></div>
                    </div>
                </div>
            </div>
        </div>

      <div class="container">

        <div class="bottom-part">
            <img src="../assets/logo-incantalia.jpg" alt="logo-incantalia" width="500" height="auto">


            <p v-if="this.lang=='it'">Incantalia si appresta a diventare una rete di negozi in tutta Italia <br> e all'estero, perfettamente integrata con un negozio online.</p>
            <p v-else>Incantalia will develop as a network of stores throughout Italy <br> and abroad, perfectly integrated with an online store.</p>
           
           
            <div class="container container-button">
                <div>
                    <div  v-if="this.lang=='it'" class="button-container">
                        <a  href="https://incantalia.it" target="_blank">
                            <button data-v-725b69c4="" class="button beige">entra in incantalia</button>
                        </a>
                    </div>
                    <div  v-else class="button-container">
                        <a  href="https://incantalia.it" target="_blank">
                            <button data-v-725b69c4="" class="button beige">Discover Incantalia</button>
                        </a>
                    </div>
                </div>
            </div>
            
       
        </div>

      </div>

      <img class="filigrana-incantalia right" src="../assets/filigrana-incantalia-right.png" alt="filigrana-incantalia">

        <!-- SEZIONE NEWS LEGATE A INCANTALIA  -->
        <div class="news-page"  v-if="this.lang != 'en' ">
            <div class="news-container container-beige container">
                <div>
                    <news-tile v-for="(n, index) in newsData" :key="'news'+index" :ndata="n"></news-tile>
                </div>
            </div>
        </div>
        
    </basic-page>
</template>

<script>
    import { fetchSinglePage, fetchNodes, getTranslation } from '../libs/drupalClient'
    import { slugify } from '../libs/utils'
    import BasicPage from './BasicPage.vue'
    import NewsTile from '../components/NewsTile.vue'

    export default {
        components: {
            NewsTile,
            BasicPage
        },
        name: 'incantalia',
        data: () => {
            return {
                currentPageId: "70581f9d-864b-4708-8569-8899d2a29af4",
                pageData: { attributes: {} },
                newsData: [],
            }
        },
        computed: {
            lang() {
                return this.$store.state.currentLang
            }
        },
        methods: {
            slugLink(name) {
                return slugify(name);
            },
            splittedTitle: function (value) {
                return value.replace(/(?![^<]*>)[^<]/g, c => `<span>${c}</span>\n`);
            },
        
        },
        watch: {
            lang() {
                getTranslation(this.$route.name, this.lang).then(res => {
                    this.$router.push({path: res})
                })

                fetchSinglePage(this.currentPageId, this.lang).then(res => {
                    this.pageData = res[0]
                    this.pageData.field_page_image = ''
                })
            }
        },
        mounted() {

            fetchNodes('news', {
                include: ['field_news_image', 'field_ref_brand']
                }, this.lang).then(res => {


                this.newsData = res
                    .filter(p => {
                        if (p.attributes.field_is_incantalia)
                            return p 
                        else
                            return false
                    })
                    .sort((a, b) => {
                        return a.attributes.field_news_date > b.attributes.field_news_date ? -1 : 1
                    })

                console.log('this.newsData',this.newsData);
            })


            fetchSinglePage(this.currentPageId, this.lang).then(res => {
                this.pageData = res[0]
                this.pageData.field_page_image = ''
                console.log('this.pageData', this.pageData);
            })

 
        }

    }
</script>

<style lang="scss" scoped>
    @import "../styles/colors";



/*MODIFICHE SOLO PER PAGINA INCANTALIA*/


    .filigrana-incantalia {
        display: none;
        position: absolute;
        pointer-events: none;
        width: 30%;
        height: auto;

        &.left {
            left: 0;
            bottom: 0;
        }
        &.right {
            top: 430px;
            right: 0;
        }
    }

    .incantalia {
        .container-beige.intro {
            margin-bottom: 5rem;
        } 
        a {
            text-decoration: none;
        }

        .bottom-part {
            padding: 5rem 0rem 0rem 0rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
                margin-top: 1rem;
                margin-bottom: 2rem;
                text-align: center;
            }

            img {
                width: 250px;
                margin-bottom: 1.5rem;
            }
            .container-button >div {
                padding-top: 0;
            }
        }
    }


    @media (max-width:768px){

        .incantalia { 
           
            br {
                display: none;
            }
        }
    }

    @media (min-width:1024px) {
        .incantalia {

        .section-title.it {
            max-width: 421px;
        }
        .section-title.en {
            max-width: 288px;
        }

        

            
    .filigrana-incantalia {
        display: block;
    }
            .central {
                margin-bottom: 0;
            }

            .text-container p {

            }

            .row-box .img {
                overflow: visible;
            }
            .row-box .img-1 {
                height: 350px;
            }

        }
        .row-box .column-2 .img.img-1{
            width: 100%;
            img {
                margin-top: 5rem;
                margin-left: -5rem;
            }

        }
    }

    @media (min-width:1200px) {
        .incantalia {
            .container-beige.intro {
                margin-bottom: 10rem;
            } 
        }
    }

    @media (min-width:1500px) {
        .incantalia {

            .central {
                margin-bottom: 20rem;
            }
            .row-box .column-2 .img.img-1 img {
                margin-top: 21rem;
                margin-left: -5rem;
}
.incantalia .row-box .img-1 {
    height: 840px;
}
        }


    }


/*FINE MODIFICHE SOLO PER PAGINA INCANTALIA*/

.images-container .img-3 img {
    width: 389px;
}

.didascalia {
    position: relative;
    //bottom: 40px;
    width: 100%;
    font-weight: 700;
    color: $mainColor;
}


    .images-container {
        display: flex;
        flex-direction: row;
        margin-top: -50px;
        margin-bottom: 4rem;

        img {
            width: 100%;
            height: auto;
        }

        .img {
            position: relative;
        }

        .img-4 {
            top: 100px;
            left: -80px;
            z-index: -1;
        }
    }

    .section-produttori {
        margin-top: 4rem;

        .section-title {
            max-width: 500px;
            margin-bottom: 3rem;
        }
    }


    .filigrana {
        position: absolute;
        right: -200px;
        top: -200px;
    }

    .filigrana-2 {
        right: auto;
        top: auto;
        bottom: -500px;
        opacity: 0.051;
        left: -200px;
    }

    .central {
        display: flex;
        position: relative;
        flex-direction: column;
        margin-bottom: 6rem;
        margin-left: auto;
        margin-right: auto;
    }

  

    .row-box {

        display: flex;
        flex-direction: column;

        .row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }



        .img {
            width: 50%;
            overflow: hidden;
            min-width: 300px;
            height: 425px;
            position: relative;

            img {
                width: 100%;
                height: auto;
                position: absolute;
            }
        }

        .img-1 {
            height: 560px;
        }

        .img-0 {
            width: 550px;
            margin: 0 auto;
            z-index: 1000;
            height: auto;
            overflow: unset;
        }



        .column,
        .column-2 {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 50%;

            .role {
                position: relative;

                &:after {
                    content: "";
                    width: 120px;
                    height: 1px;
                    position: absolute;
                    background-color: $mainColor;
                    left: 12%;
                    top: 13px;
                }
            }
        }

        .column p,
        .column .img {
            margin-left: 0;
            margin-right: 50px;
        }

        .column-2 {

            p,
            .img {
                margin-right: 0;
                margin-left: 50px;
            }
        }

        .row-video {
            position: relative;
            width: 100%;
            margin-bottom: 6rem;

            .video-container {
                position: absolute;
                bottom: -160px;
                right: 5%;
                width: 701px;
                height: 394px;
            }
        }
    }

    .container {
        .role {
            position: relative;
            margin-top: 4rem;
            margin-bottom: 5rem;
            width: fit-content;
            font-size: 1rem;

            &:after {
                content: "";
                width: 120px;
                height: 1px;
                position: absolute;
                background-color: $mainColor;
                right: -150px;
                top: .75rem;
            }
        }


    }

    .produttori {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;

        a {
            display: block;
            text-decoration: none;
        }

        h2 {
            width: 50%;
        }
    }

    .main-block-left {
        .column {
            width: 46%;
        }

        .img {
            width: 100%;
            //overflow: hidden;
            height: 100%;
            position: relative;
            margin-left: auto;
            margin-top: -200px;
            z-index: -1;

            img {
                width: 100%;
                //margin-left: 40%;
                right: 0;
                height: auto;
                position: relative;
            }
        }
    }

    .main-block-right {
        position: relative;
        display: flex;

        .column {
            width: 50%;
            margin-left: 10%;
        }

        .img {
            position: relative;
            z-index: -1;
        }
    }


    @media (max-width:1200px) {
        .row-box .row-video .video-container {
            bottom: -10px;
            width: 491px;
            height: 276px;
            right: 0;
        }
    }

    @media (max-width:992px) {
        .didascalia {
            position: relative;
            left: 0;
            bottom: 0;
            font-weight: 500;
            width: 100%;
        }
        .container .role {
            margin-bottom: 4rem;
        }
        p {
            margin-top: 1rem;
            margin-bottom: 2rem;
        }

        .row-box .row {
            flex-direction: column;
        }

        .container .role {
            margin-top: 2rem;
        }

        .row-box .column,
        .row-box .column-2 {
            width: auto;
        }

        .row-box .img {
            width: 100%;
        }

        .row-box p {
            margin-top: 1rem;
            margin-bottom: 2rem;
        }

        .row-box .column p,
        .row-box .column .img,
        .row-box .column-2 p,
        .row-box .column-2 .img {
            margin-right: 0;
            margin-left: 0px;
        }

        .row-box .img-1 {
            height: auto;
            width: 100%;

            img {
                position: relative;
            }
        }

        .row-box .row-video .video-container {
            position: relative;
            width: 100%;
            height: 100%;
        }

        .central {
            margin-bottom: 0rem;
        }

        .section-produttori {
            margin-top: 0rem;
        }

        .section-produttori .section-title {
            margin-bottom: 2rem;
        }

        .main-block-left .column {
            width: auto ;
        }

        .main-block-left .img{
            margin-top: 0;
            //width: auto;
            img {
                width: 100%;
                margin-left: 0;
            }
        }

        .main-block-right {
            flex-direction: column;
            .img {
                margin-bottom: 2rem;
            }
            .column {
                width: auto;
                margin-left: 0%;
            }
        }
        .images-container {
            margin-top: 0px;
            margin-bottom: 4rem;
        }

    }

  @media (max-width:992px) {
    .images-container{
        display: none;
    }
}


@media (max-width:768px){
    .produttori{
        ::v-deep a:last-child{
            .img{
                height: 120px;
            }
        }
    }
}
</style>